.navbar-nav.navbar-center {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  font-size: large;
  font-weight: 700;
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%; 
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

section.resume-section {
  min-height: 100vh;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 10rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
    
}

#about {  
  color: #343a40;  

}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  
}

.mb-0 {
  font-size: 4rem;
}

.mb-01 {
  color: lightslategray;  
}

.mb-01Project {
  color: lightslategray; 
  padding-bottom: 5rem;
}
.section-header {
  font: Arial;
  font-size: 700%;  
  font-style: italic;
}

.sub-header {
  font-size: 2rem;  
  color: lightslategray;
  text-decoration: none;
}

.sub-header2{
  font-size: 2rem;
  color: lightslategray;
}

.sub-header3{
  font-size: 4rem;
  color: lightslategray;
}

.text-primary {
  color: green;
}

.sub-header:hover {
  color: darkorange;
  cursor: pointer;
}

.subheading {  
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2.5rem;  
  color: lightslategray;
}


.subheading3 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2rem;  
  color: darkorange;
}

.icon-list-div {
  display: inline;
}

.icon-list li{  
  list-style-type: none;  
  display: inline;
}

.knowledge-list.div {
  display: inline;
}

.knowledge-list li{
  list-style-type: none;  
  font-size: 2rem;  
  text-transform: uppercase;
  color: darkslategray;  
  display: inline;
  margin-right: 1rem;
}

.proficient-list.div {
  
}

.proficient-list li{
  list-style-type: none;  
  font-size: 2rem;  
  text-transform: uppercase;
  color: darkslategray;  
  
  margin-right: 1rem;
}

.MuiIconButton-root {
  color: blue;
}

.projects {
  background-color: blue;
}

.ul {
  list-style-type: none;
}

.projectList {  
    margin: 10px 0;
  
}





















